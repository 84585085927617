import { ProductCategory } from "@/types/apiTypes";

interface ThreeScreens {
  desktop: string;
  tablet: string;
  mobile: string;
}

export interface CampaignPayload {
  active: boolean;
  discount: {
    code: string;
    value: number;
  };
  edgeColor: string;
  campaignFor: {
    [key in ProductCategory]: boolean;
  };
  assetUrls: {
    orderRecipeMainBanner: ThreeScreens;
    productSelectorDesktopBanner: ThreeScreens;
    prod_Subscr: ThreeScreens;
    prod_Single: ThreeScreens;
    orderRecipeMiddleBanner: ThreeScreens;
    checkoutMainBanner: ThreeScreens;
  };
}

const isCampaignActive = import.meta.env.VITE_CAMPAIGN_ACTIVE === "true";

export const CAMPAIGN: CampaignPayload = {
  active: isCampaignActive,
  discount: {
    code: "welcome33",
    value: 25,
  },
  campaignFor: {
    [ProductCategory.GO_SINGLE_PURCHASE]: false,
    [ProductCategory.GO_SUBSCRIPTION]: true,
  },
  edgeColor: "#8BE100",
  assetUrls: {
    orderRecipeMainBanner: {
      desktop: "",
      tablet: "",
      mobile: "",
    },
    productSelectorDesktopBanner: {
      desktop: "",
      tablet: "",
      mobile: "",
    },
    prod_Subscr: {
      desktop: "",
      tablet: "",
      mobile: "",
    },
    prod_Single: {
      desktop: "",
      tablet: "",
      mobile: "",
    },
    orderRecipeMiddleBanner: {
      desktop: "",
      tablet: "",
      mobile: "",
    },
    checkoutMainBanner: {
      desktop: new URL("@/assets/images/w-33-checkout-main-banner-desktop.webp", import.meta.url).href,
      tablet: "",
      mobile: new URL("@/assets/images/w-33-checkout-main-banner-mobile.webp", import.meta.url).href,
    },
  },
};
