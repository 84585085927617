import {
  type BasicQuestionKey,
  HealthQuestionKey,
  type SectionKey,
  type NoneQuestionKey,
  SubSectionKey,
  YesNo,
  LifestyleQuestionKey,
  type AnswerNumber,
  type AnswerNumberList,
  type AnswerText,
} from "@/types/questions";
import type { QuestionOption } from "@/types/uiTypes";
import { type Currency, type Medication, ProductCategory } from "@/types/apiTypes";
import type { InjectionKey, Ref } from "vue";
import type { CampaignPayload } from "@/utils/campaign-trackers";

export enum LOCALE {
  DE = "de",
  EN = "en",
}
export const DEFAULT_LOCALE = LOCALE.EN;

export const sectionToNumberStep: {
  [key in SectionKey]: number;
} = {
  "section-intro": 0,
  "section-health": 1,
  "section-basics": 2,
  "section-lifestyle": 3,
};

export enum ExternalID {
  AGE_LE_17 = "age-less-equal-17",
  AGE_LE_40 = "age-less-equal-40",
  AGE_LE_60 = "age-less-equal-60",
  AGE_ABOVE_60 = "age-above-60",
  HEIGHT_L_150 = "height-less-150",
  HEIGHT_LE_180 = "height-less-equal-180",
  HEIGHT_ABOVE_180 = "height-above-180",
  WEIGHT_L_50 = "weight-less-50",
  WEIGHT_L_75 = "weight-less-75",
  WEIGHT_LE_90 = "weight-less-equal-90",
  WEIGHT_ABOVE_90 = "weight-above-90",
  BMI_L_18 = "bmi-less-18.5",
  BMI_LE_25 = "bmi-less-equal-25",
  BMI_LE_30 = "bmi-less-equal-30",
  BMI_LE_35 = "bmi-less-equal-35",
  BMI_LE_40 = "bmi-less-equal-40",
  BMI_ABOVE_40 = "bmi-above-40",
  FEMALE = "female",
  MALE = "male",
  NOT_SPECIFIC_GENDER = "not-specific-gender",
  PREGNANCY = "pregnancy-true",
  NO_PREGNANCY_PLANS = "nopregnancyplans",
  PREGNANCY_PLAN = "pregnancy-plan",
  PREGNANCY_IN_PROGRESS = "pregnancy-inprogress",
  NURSING = "nursing",
  SKIN_YES = "skin-hair-nails-yes",
  SKIN_NO = "skin-hair-nails-no",
  LIBIDO_YES = "libido-yes",
  LIBIDO_NO = "libido-no",
  MEMORY_YES = "memory-and-focus-yes",
  MEMORY_NO = "memory-and-focus-no",
  LIVER_YES = "liver-function-yes",
  LIVER_NO = "liver-function-no",
  SLEEP_YES = "sleep-and-anxiety-yes",
  SLEEP_NO = "sleep-and-anxiety-no",
  AGING_YES = "healthy-aging-yes",
  AGING_NO = "healthy-aging-no",
  HEART_YES = "heart-and-blood-vessels-yes",
  HEART_NO = "heart-and-blood-vessels-no",
  IRON_YES = "iron-levels-yes",
  BONES_YES = "bones-and-joints-yes",
  BONES_NO = "bones-and-joints-no",
  VEGETARIAN_YES = "vegetarian-yes",
  VEGETARIAN_NO = "vegetarian-no",
  VEGAN_YES = "vegan-yes",
  VEGAN_NO = "vegan-no",
  ALLERGIC_C_YES = "allergic-vitamin-c-yes",
  ALLERGIC_C_NO = "allergic-vitamin-c-no",
  ALLERGIC_D_YES = "allergic-vitamin-d-yes",
  ALLERGIC_D_NO = "allergic-vitamin-d-no",
  ALLERGIC_B_YES = "allergic-vitamin-b-yes",
  ALLERGIC_B_NO = "allergic-vitamin-b-no",
  ALLERGIC_IODINE_NO = "allergic-iodine-no",
  NO_ALLERGIES = "no-allergies",
  STATINS_NO = "statins-no",
  BLOOD_THINNERS_NO = "blood-thinners-no",
  DIURETICS_NO = "diuretics-no",
  COLESTEROL = "high-colesterol",
  HYPERTIROIDISM_NO = "hyperthyroidism-no",
  SEDENTARY = "sedentary-lifestyle-or-disability",
  LIGHTLY_ACTIVE = "desk-job-dont-move",
  MODERATELY_ACTIVE = "moderately-active-desk-job-3-trainings",
  VERY_ACTIVE = "very-active-training-1h-per-day",
  NONE = "none",
  MUSCLE_YES = "muscle-strength-yes",
  MUSCLE_NO = "muscle-strength-no",
  OPERATIVE_YES = "pre-post-operative-yes",
  PESCETARIANISM = "pescetarianism-yes",
  NO_DIET = "no-specific-diet-currently",
  ALLERGIC_IODINE_YES = "allergic-iodine-yes",
  MEDS_STATINS = "algo-medications-statins",
  MEDS_THINNERS = "algo-medications-blood-thinners",
  MEDS_DIURETICS = "algo-medications-diuretics",
  MEDS_NO = "algo-medications-none",
  HYPERTIROIDISM_YES = "hyperthyroidism-yes",
  NO_MEDICAL_COND = "medical-condition-no",
  GRANULES = "granules-yes",
  COLDS_NO = "colds-no",
  COLDS_YES = "colds-yes",
  LOSE_WEIGHT_YES = "lose-weight",
  LOSE_WEIGHT_NO = "lose-weight-no",
  MUSCLE_MASS_YES = "muscle-mass",
  MUSCLE_MASS_NO = "muscle-mass-no",
  STAMINA_YES = "stamina-yes",
  STAMINA_NO = "stamina-no",
  SMOKE_YES = "smoking-yes",
  SMOKE_NO = "smoking-no",
  LOW_BLOOD_PRESSURE_YES = "low-blood-pressure",
  LOW_BLOOD_PRESSURE_NO = "low-blood-pressure-no",
  ALLERGIC_IRON_YES = "allergic-iron",
  ALLERGIC_IRON_NO = "allergic-iron-no",

  // ALGO V2 MANDATORY KEY
  ALGO_V2 = "use-new-algorithm",
}

export const questionToPhraseStep: {
  [key in
    | BasicQuestionKey
    | HealthQuestionKey
    | LifestyleQuestionKey
    | NoneQuestionKey.UNDER_18
    | SectionKey
    | SubSectionKey]: string;
} = {
  //health-questions
  "h-gender": "05",
  "h-concerns": "14",
  "h-brain-fog": "16",
  "h-cognitive": "17",
  "h-blood-pressure": "18",
  "h-edema": "19",
  "h-anxiety": "20",
  "h-mood": "21",
  "h-depression": "22",
  "h-asleep": "23",
  "h-panic": "24",
  "h-fitness-goal": "25",
  "h-physical-activity": "26",
  "h-mental-worn-out": "27",
  "h-joint-pain": "28",
  "h-broken": "29",
  "h-skin-type": "30",
  "h-skin-issues": "31",
  "h-skin-breakouts": "32",
  "h-skin-flaky": "33",
  "h-hair": "34",
  "h-fingernails": "35",
  "h-city": "36",
  "h-procedure": "37",
  "h-operative-concern": "38",
  "h-sex-drive": "39",
  "h-improve-sex": "40",
  "h-libido-simptoms": "41",
  "h-erectile-simptoms": "42",
  "h-fertility": "43",
  "h-alcoholic": "44",
  "h-anemia": "45",
  "h-colds": "46",
  "h-concentrating": "47",
  "h-energy": "48",
  "h-sleep-quality": "49",
  "h-seeing": "50",
  "h-dry-eyes": "51",
  "h-stress": "52",
  "h-athlete": "53",
  "h-sweat": "54",
  "h-workout": "55",
  "h-cramps": "56",
  "h-muscle-injuries": "57",
  "h-injuries": "58",
  //basic-questions
  "b-dob": "02",
  "b-height": "03",
  "b-weight": "04",
  "b-pre-post-natal": "06",
  "b-pregnant-or-planning": "07",
  "b-which-describes": "08",
  "b-pregnancy-weeks": "09",
  "b-ask-reproductive": "10",
  "b-menstrual": "11",
  "b-premenstrual": "12",
  "b-menopause": "13",
  // lifestyle-questions
  "l-activity": "59",
  "l-meat": "60",
  "l-fish": "61",
  "l-diary": "62",
  "l-fruit": "63",
  "l-eggs": "64",
  "l-legumes": "65",
  "l-diet": "66",
  "l-allergic": "67",
  "l-meds": "68",
  "l-medical-cond": "69",
  "l-cancer": "70",
  "l-smoke": "71",
  "l-caffeinate": "72",
  "l-full-name": "01",
  "l-e-mail": "73",
  //subsections
  "subsection-basics": "BASICS",
  "subsection-prenatal": "PRENATAL",
  "subsection-reproductive-health": "REPRODUCTIVE_HEALTH",
  "subsection-health-goals": "HEALTH_GOALS",
  "subsection-health-concerns": "HEALTH_CONCERNS",
  "subsection-memory-focus": "MEMORY_FOCUS",
  "subsection-healthy-aging": "HEALTHY_AGING",
  "subsection-heart-blood": "HEART_BLOOD",
  "subsection-sleep-anxiety": "SLEEP_ANXIETY",
  "subsection-muscle-strength": "MUSCLE_STRENGTH",
  "subsection-bones-joints": "BONES_JOINTS",
  "subsection-skin-hair-nails": "SKIN_HAIR_NAILS",
  "subsection-operative-period": "OPERATIVE_PERIOD",
  "subsection-libido": "LIBIDO",
  "subsection-liver": "LIVER_FUNCTION",
  "subsection-iron": "IRON",
  "subsection-lifestyle": "LIFESTYLE",
  //non-question
  "b-under-18": "UNDER_18",
  //sections
  "section-intro": "SECTION_1",
  "section-basics": "SECTION_2",
  "section-health": "SECTION_3",
  "section-lifestyle": "SECTION_4",
};

export const YesNoOptions: QuestionOption[] = [
  { id: 1, value: YesNo.YES, phraseKey: "OPTION.YES" },
  { id: 2, value: YesNo.NO, phraseKey: "OPTION.NO" },
];

export const HealthConcernsValues = [
  "skin",
  "libido",
  "memory",
  "liver",
  "sleep",
  "aging",
  "heart",
  "iron",
  "bones",
  "muscle",
  "operative",
] as const;

export const HealthConcernsToPhraseKeys: { [key in (typeof HealthConcernsValues)[number]]: string } = {
  skin: "HEALTH_GOAL.SKIN",
  libido: "HEALTH_GOAL.LIBIDO",
  memory: "HEALTH_GOAL.MEMORY",
  liver: "HEALTH_GOAL.LIVER",
  sleep: "HEALTH_GOAL.SLEEP",
  aging: "HEALTH_GOAL.AGING",
  heart: "HEALTH_GOAL.HEART",
  iron: "HEALTH_GOAL.IRON",
  bones: "HEALTH_GOAL.BONES",
  muscle: "HEALTH_GOAL.MUSCLE",
  operative: "HEALTH_GOAL.OPERATIVE",
};

export const HealthConcernsOptions: QuestionOption[] = [
  { id: 7, value: "skin", phraseKey: "SKIN", apiKey: ExternalID.SKIN_YES, icon: "hair" },
  { id: 8, value: "libido", phraseKey: "LIBIDO", apiKey: ExternalID.LIBIDO_YES, icon: "libido" },
  { id: 9, value: "memory", phraseKey: "MEMORY", apiKey: ExternalID.MEMORY_YES, icon: "memory" },
  { id: 10, value: "liver", phraseKey: "LIVER", apiKey: ExternalID.LIVER_YES, icon: "liver" },
  { id: 11, value: "sleep", phraseKey: "SLEEP", apiKey: ExternalID.SLEEP_YES, icon: "sleep" },
  { id: 12, value: "aging", phraseKey: "AGING", apiKey: ExternalID.AGING_YES, icon: "aging" },
  { id: 13, value: "heart", phraseKey: "HEART", apiKey: ExternalID.HEART_YES, icon: "heart" },
  { id: 14, value: "iron", phraseKey: "IRON", apiKey: ExternalID.IRON_YES, icon: "iron" },
  { id: 15, value: "bones", phraseKey: "BONES", apiKey: ExternalID.BONES_YES, icon: "bones" },
  { id: 16, value: "muscle", phraseKey: "MUSCLE", apiKey: ExternalID.MUSCLE_YES, icon: "muscle" },
  { id: 17, value: "operative", phraseKey: "OPERATIVE", apiKey: ExternalID.OPERATIVE_YES, icon: "operation" },
];

export const CurrentFitnessGoalsOptions: QuestionOption[] = [
  { id: 29, value: "build-muscle", phraseKey: "BUILD_MUSCLE" },
  { id: 30, value: "lose-weight", phraseKey: "LOSE_WEIGHT" },
  { id: 31, value: "improve-stamina", phraseKey: "IMPROVE_STAMINA" },
  { id: 32, value: "general-well", phraseKey: "GENERAL_WELL" },
];

export const allergiesOptions = [
  { id: 99, value: "allergic-b", phraseKey: "ALLERGIC_B", apiKey: ExternalID.ALLERGIC_B_YES },
  { id: 100, value: "allergic-c", phraseKey: "ALLERGIC_C", apiKey: ExternalID.ALLERGIC_C_YES },
  { id: 101, value: "allergic-d", phraseKey: "ALLERGIC_D", apiKey: ExternalID.ALLERGIC_D_YES },
  { id: 102, value: "allergic-iodine", phraseKey: "ALLERGIC_IODINE", apiKey: ExternalID.ALLERGIC_IODINE_YES },
  { id: 103, value: "none-above", phraseKey: "NONE_ABOVE", negative: true, apiKey: ExternalID.NO_ALLERGIES },
];

export const concernToSubsectionKey: {
  [key in string]: SubSectionKey;
} = {
  skin: SubSectionKey.SKIN_HAIR_NAILS,
  libido: SubSectionKey.LIBIDO,
  memory: SubSectionKey.MEMORY_FOCUS,
  liver: SubSectionKey.LIVER_FUNCTION,
  sleep: SubSectionKey.SLEEP_ANXIETY,
  aging: SubSectionKey.HEALTHY_AGING,
  heart: SubSectionKey.HEART_BLOOD,
  iron: SubSectionKey.IRON,
  bones: SubSectionKey.BONES_JOINTS,
  muscle: SubSectionKey.MUSCLE_STRENGTH,
  operative: SubSectionKey.OPERATIVE_PERIOD,
};

export enum Store {
  USA = "usa",
}

export enum SubscriptionPeriod {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
}

export type SubscriptionPlans = {
  [key in Store]: { [key in SubscriptionPeriod]: { price: number; delivery: number } };
};

export const CurrencyToSymbol: { [key in Currency]: string } = {
  EUR: "€",
  GBP: "£",
  AED: "AED",
  USD: "$",
};

export enum CountryCode {
  BE = "BE", // Belgium
  AT = "AT", // Austria
  BG = "BG", // Bulgaria
  HR = "HR", // Croatia
  CY = "CY", // Cyprus
  CZ = "CZ", // Czech Republic
  DK = "DK", // Denmark
  EE = "EE", // Estonia
  FI = "FI", // Finland
  DE = "DE", // Germany
  CH = "CH", // Switzerland
  LV = "LV", // Latvia
  FR = "FR", // France
  GR = "GR", // Greece
  HU = "HU", // Hungary
  IE = "IE", // Ireland
  IT = "IT", // Italy
  LT = "LT", // Lithuania
  LU = "LU", // Luxembourg
  MT = "MT", // Malta
  NL = "NL", // Netherlands
  SE = "SE", // Sweden
  PL = "PL", // Poland
  PT = "PT", // Portugal
  RO = "RO", // Romania
  SK = "SK", // Slovakia
  SI = "SI", // Slovenia
  ES = "ES", // Spain
  NO = "NO", // Norway

  AE = "AE", // United Arab Emirates
  BH = "BH", // Bahrain
  KW = "KW", // Kuwait
  QA = "QA", // Qatar
  SA = "SA", // Saudi Arabia
  OM = "OM", // Oman

  US = "US", // United States
  IL = "IL", // ISRAEL
  ZA = "ZA", // SOUTH AFRICA
  CA = "CA", // Canada
  AU = "AU", // Australia
  HK = "HK", // Hong Kong
  MY = "MY", // Malaysia
  SG = "SG", // Singapore
  BR = "BR", // Brazil
  RW = "RW", // Rwanda
  GI = "GI", // Gibraltar

  GB = "GB", // United Kingdom
}

export const UAECountries = [
  CountryCode.AE,
  CountryCode.BH,
  CountryCode.KW,
  CountryCode.QA,
  CountryCode.SA,
  CountryCode.OM,
] as string[];

export const countryCodeToPhrase: { [key in CountryCode]: string } = {
  BE: "PAY.COUNTRY.BE",
  AT: "PAY.COUNTRY.AT",
  BG: "PAY.COUNTRY.BG",
  HR: "PAY.COUNTRY.HR",
  CY: "PAY.COUNTRY.CY",
  CZ: "PAY.COUNTRY.CZ",
  DK: "PAY.COUNTRY.DK",
  EE: "PAY.COUNTRY.EE",
  FI: "PAY.COUNTRY.FI",
  DE: "PAY.COUNTRY.DE",
  CH: "PAY.COUNTRY.CH",
  LV: "PAY.COUNTRY.LV",
  FR: "PAY.COUNTRY.FR",
  GR: "PAY.COUNTRY.GR",
  HU: "PAY.COUNTRY.HU",
  IE: "PAY.COUNTRY.IE",
  IT: "PAY.COUNTRY.IT",
  LT: "PAY.COUNTRY.LT",
  LU: "PAY.COUNTRY.LU",
  MT: "PAY.COUNTRY.MT",
  NL: "PAY.COUNTRY.NL",
  SE: "PAY.COUNTRY.SE",
  PL: "PAY.COUNTRY.PL",
  PT: "PAY.COUNTRY.PT",
  RO: "PAY.COUNTRY.RO",
  SK: "PAY.COUNTRY.SK",
  SI: "PAY.COUNTRY.SI",
  ES: "PAY.COUNTRY.ES",
  AE: "PAY.COUNTRY.UAE",
  BH: "PAY.COUNTRY.BH",
  KW: "PAY.COUNTRY.KW",
  QA: "PAY.COUNTRY.QA",
  SA: "PAY.COUNTRY.SA",
  AU: "PAY.COUNTRY.AU",
  CA: "PAY.COUNTRY.CA",
  GB: "PAY.COUNTRY.GB",
  HK: "PAY.COUNTRY.HK",
  MY: "PAY.COUNTRY.MY",
  SG: "PAY.COUNTRY.SG",
  IL: "PAY.COUNTRY.IL",
  ZA: "PAY.COUNTRY.ZA",
  US: "PAY.COUNTRY.US",
  BR: "PAY.COUNTRY.BR",
  RW: "PAY.COUNTRY.RW",
  OM: "PAY.COUNTRY.OM",
  NO: "PAY.COUNTRY.NO",
  GI: "PAY.COUNTRY.GI",
};

export const LocalStorageKeys = {
  answer: "questionnaire-answers",
  lastPage: "last-page",
  formulaRecipe: "bioniq-formula-recipe",
  questions: "bioniq-questions",
  marketingConsent: "marketing-consent",
  smsConsent: "sms-consent",
  contactEmail: "hubspot-contact-email",
  countryCode: "country-code",
  availableProducts: "available-products",
  selectedProductCategory: "selected-product-category",
  discount: "discount",
  selectedPaymentMethodType: "selected-payment-method-type",
  ty_email: "ty_email",
  ty_fullName: "ty_fullName",
  ty_delivery: "ty_delivery",
  ty_phone: "ty_phone",
  ty_payment: "ty_payment",
  locale: "locale",
};

export const LegalLinks: { [key in Store]: any } = {
  usa: {
    privacy: () => "https://bioniq.com/en/privacy", // TODO ask for links
    imprint: () => "https://bioniq.com/en/go/imprint",
    terms: () => "https://bioniq.com/en/legal",
  },
};

export const prefillEmail = "prefilljohn_2@mailinator.com";

export const prefillHealthAnswers: { [key in HealthQuestionKey]: AnswerNumber | AnswerNumberList | AnswerText } = {
  "h-gender": { value: 1 } as AnswerNumber,
  "h-concerns": { value: [14, 10] } as AnswerNumberList,
  "h-brain-fog": { value: null } as AnswerNumber,
  "h-cognitive": { value: null } as AnswerNumber,
  "h-blood-pressure": { value: null } as AnswerNumber,
  "h-edema": { value: null } as AnswerNumber,
  "h-anxiety": { value: null } as AnswerNumber,
  "h-mood": { value: null } as AnswerNumber,
  "h-depression": { value: null } as AnswerNumber,
  "h-asleep": { value: null } as AnswerNumber,
  "h-panic": { value: null } as AnswerNumber,
  "h-fitness-goal": { value: null } as AnswerNumber,
  "h-physical-activity": { value: null } as AnswerNumber,
  "h-mental-worn-out": { value: null } as AnswerNumber,
  "h-joint-pain": { value: null } as AnswerNumber,
  "h-broken": { value: null } as AnswerNumber,
  "h-skin-type": { value: null } as AnswerNumber,
  "h-skin-issues": { value: [] } as AnswerNumberList,
  "h-skin-breakouts": { value: null } as AnswerNumber,
  "h-skin-flaky": { value: null } as AnswerNumber,
  "h-hair": { value: null } as AnswerNumber,
  "h-fingernails": { value: null } as AnswerNumber,
  "h-city": { value: null } as AnswerNumber,
  "h-procedure": { value: "" } as AnswerText,
  "h-operative-concern": { value: null } as AnswerNumber,
  "h-sex-drive": { value: null } as AnswerNumber,
  "h-improve-sex": { value: null } as AnswerNumber,
  "h-libido-simptoms": { value: [] } as AnswerNumberList,
  "h-erectile-simptoms": { value: null } as AnswerNumber,
  "h-fertility": { value: null } as AnswerNumber,
  "h-alcoholic": { value: 2 } as AnswerNumber,
  "h-anemia": { value: 2 } as AnswerNumber,
  "h-colds": { value: 2 } as AnswerNumber,
  "h-concentrating": { value: 73 } as AnswerNumber,
  "h-energy": { value: 2 } as AnswerNumber,
  "h-sleep-quality": { value: 1 } as AnswerNumber,
  "h-seeing": { value: 2 } as AnswerNumber,
  "h-dry-eyes": { value: 2 } as AnswerNumber,
  "h-stress": { value: 80 } as AnswerNumber,
  "h-athlete": { value: 2 } as AnswerNumber,
  "h-sweat": { value: null } as AnswerNumber,
  "h-workout": { value: null } as AnswerNumber,
  "h-cramps": { value: null } as AnswerNumber,
  "h-muscle-injuries": { value: null } as AnswerNumber,
  "h-injuries": { value: [] } as AnswerNumberList,
};

export const prefillBasicAnswers: { [key in BasicQuestionKey]: AnswerNumber | AnswerNumberList | AnswerText } = {
  "b-dob": { value: "02/02/2000" } as AnswerText,
  "b-height": { value: "165" } as AnswerText,
  "b-weight": { value: "50" } as AnswerText,
  "b-pre-post-natal": { value: null } as AnswerNumber,
  "b-pregnant-or-planning": { value: null } as AnswerNumber,
  "b-which-describes": { value: [] } as AnswerNumberList,
  "b-pregnancy-weeks": { value: "" } as AnswerText,
  "b-ask-reproductive": { value: null } as AnswerNumber,
  "b-menstrual": { value: null } as AnswerNumber,
  "b-premenstrual": { value: null } as AnswerNumber,
  "b-menopause": { value: null } as AnswerNumber,
};

export const prefillLifeStyleAnswers: {
  [key in LifestyleQuestionKey]: AnswerNumber | AnswerNumberList | AnswerText | { value: Medication[] };
} = {
  "l-activity": { value: 78 } as AnswerNumber,
  "l-meat": { value: 81 } as AnswerNumber,
  "l-fish": { value: 84 } as AnswerNumber,
  "l-diary": { value: 87 } as AnswerNumber,
  "l-fruit": { value: 90 } as AnswerNumber,
  "l-eggs": { value: 92 } as AnswerNumber,
  "l-legumes": { value: 94 } as AnswerNumber,
  "l-diet": { value: 96 } as AnswerNumber,
  "l-allergic": { value: [103] } as AnswerNumberList,
  "l-meds": { value: [] } as { value: Medication[] },
  "l-medical-cond": { value: [106] } as AnswerNumberList,
  "l-cancer": { value: 2 } as AnswerNumber,
  "l-smoke": { value: 109 } as AnswerNumber,
  "l-caffeinate": { value: 111 } as AnswerNumber,
  "l-full-name": { value: "Prefill John2" } as AnswerText,
  "l-e-mail": { value: prefillEmail } as AnswerText,
};

export const NO_ANSWER_GIVEN = "No answer given";

export const ABTestedStores = [Store.USA];

export enum CheckoutVersion {
  VERS_A = "bioniq_go_ab_a",
  VERS_B = "bioniq_go_ab_b",
}

export enum CheckoutShopify {
  CONTROL = "",
  B = "B",
}

export enum FormulaPresentation {
  Control = "",
  B = "B",
}

export enum SectionIntroABVersion {
  Control = "",
  B = "B",
}

export const CampaignActiveInjectionKey = Symbol() as InjectionKey<CampaignPayload>;

export const USStates = [
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia", code: "VA" },
  { name: "Washington", code: "WA" },
  { name: "Washington DC", code: "DC" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
] as const;

export type USState = (typeof USStates)[number];

export const CountriesShippedByFedex: CountryCode[] = [CountryCode.US, CountryCode.CA, CountryCode.GB];

export const CheckoutVersionInjectionKey = Symbol() as InjectionKey<Ref<CheckoutVersion>>;

export const GooglePredictableCountries = [CountryCode.US, CountryCode.DE, CountryCode.GB, CountryCode.SA];

export const giftPrices: { [key in Currency]: number[] } = {
  USD: [20, 89, 29, 12], // Travel, Consultation, Online Classes, Health and Nutrition
  EUR: [20, 80, 28, 12],
  GBP: [15, 70, 20, 15],
  AED: [70, 325, 105, 50],
};

export const CurrenciesToCountryCodes: { [key in Currency]: CountryCode[] } = {
  USD: [
    CountryCode.IL,
    CountryCode.ZA,
    CountryCode.CA,
    CountryCode.AU,
    CountryCode.HK,
    CountryCode.MY,
    CountryCode.SG,
    CountryCode.US,
    CountryCode.BR,
    CountryCode.RW,
    CountryCode.GI,
  ],
  EUR: [
    CountryCode.BE,
    CountryCode.AT,
    CountryCode.BG,
    CountryCode.HR,
    CountryCode.CY,
    CountryCode.CZ,
    CountryCode.DK,
    CountryCode.EE,
    CountryCode.FI,
    CountryCode.DE,
    CountryCode.CH,
    CountryCode.LV,
    CountryCode.FR,
    CountryCode.GR,
    CountryCode.HU,
    CountryCode.IE,
    CountryCode.IT,
    CountryCode.LT,
    CountryCode.LU,
    CountryCode.MT,
    CountryCode.NL,
    CountryCode.SE,
    CountryCode.PL,
    CountryCode.PT,
    CountryCode.RO,
    CountryCode.SK,
    CountryCode.SI,
    CountryCode.ES,
    CountryCode.NO,
  ],
  GBP: [CountryCode.GB],
  AED: [CountryCode.AE, CountryCode.BH, CountryCode.KW, CountryCode.QA, CountryCode.SA, CountryCode.OM],
};

export const GCCRegionDefaultLocation = { lat: 23.8859, lng: 45.0792 };

export const productTypeMapper = {
  [ProductCategory.GO_SINGLE_PURCHASE]: "go_light",
  [ProductCategory.GO_SUBSCRIPTION]: "go_subscription",
};
