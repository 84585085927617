import type { Medication } from "./apiTypes";
import type { QuestionOption, QuestionOptionCheckbox } from "./uiTypes";

export enum QuestionType {
  TEXT = "text",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  DATE = "date",
  YES_NO = "yes_no",
  NUMBER = "number",
  NUMBER_HEIGHT_USA = "number_height_usa",
  MULTIPLE = "multiple",
  TEXT_PRED = "text_pred",
}

export enum SectionKey {
  INTRO = "section-intro",
  BASICS = "section-basics",
  HEALTH = "section-health",
  LIFESTYLE = "section-lifestyle",
}

export enum SubSectionKey {
  BASICS = "subsection-basics",
  PRENATAL = "subsection-prenatal",
  REPRODUCTIVE_HEALTH = "subsection-reproductive-health",
  HEALTH_GOALS = "subsection-health-goals",
  HEALTH_CONCERNS = "subsection-health-concerns",
  MEMORY_FOCUS = "subsection-memory-focus",
  HEALTHY_AGING = "subsection-healthy-aging",
  HEART_BLOOD = "subsection-heart-blood",
  SLEEP_ANXIETY = "subsection-sleep-anxiety",
  MUSCLE_STRENGTH = "subsection-muscle-strength",
  BONES_JOINTS = "subsection-bones-joints",
  SKIN_HAIR_NAILS = "subsection-skin-hair-nails",
  OPERATIVE_PERIOD = "subsection-operative-period",
  LIBIDO = "subsection-libido",
  LIVER_FUNCTION = "subsection-liver",
  IRON = "subsection-iron",
  LIFESTYLE = "subsection-lifestyle",
}

export enum BasicQuestionKey {
  DOB = "b-dob",
  HEIGHT = "b-height",
  WEIGHT = "b-weight",
  PRE_POST_NATAL = "b-pre-post-natal",
  PREGNANT_OR_PLANNING = "b-pregnant-or-planning",
  WHICH_DESCRIBES = "b-which-describes",
  PREGNANCY_WEEKS = "b-pregnancy-weeks",
  ASK_REPRODUCTIVE = "b-ask-reproductive",
  MENSTRUAL = "b-menstrual",
  PREMENSTRUAL = "b-premenstrual",
  MENOPAUSE = "b-menopause",
}

export enum HealthQuestionKey {
  GENDER = "h-gender",
  CONCERNS = "h-concerns",
  BRAIN_FOG = "h-brain-fog",
  COGNITIVE = "h-cognitive",
  BLOOD_PRESSURE = "h-blood-pressure",
  EDEMA = "h-edema",
  ANXIETY = "h-anxiety",
  MOOD = "h-mood",
  DESPRESSION = "h-depression",
  ASLEEP = "h-asleep",
  PANIC = "h-panic",
  FITNESS_GOAL = "h-fitness-goal",
  PHYSICAL_ACTIVITY = "h-physical-activity",
  MENATAL_WORN_OUT = "h-mental-worn-out",
  JOINT_PAIN = "h-joint-pain",
  BROKEN = "h-broken",
  SKIN_TYPE = "h-skin-type",
  SKIN_ISSUES = "h-skin-issues",
  SKIN_BREAKOUTS = "h-skin-breakouts",
  SKIN_FLAKY = "h-skin-flaky",
  HAIR = "h-hair",
  FINGERNAILS = "h-fingernails",
  CITY = "h-city",
  PROCEDURE = "h-procedure",
  OPERATIVE_CONCERN = "h-operative-concern",
  SEX_DRIVE = "h-sex-drive",
  IMPROVE_SEX = "h-improve-sex",
  LIBIDO_SIMPTOMS = "h-libido-simptoms",
  ERECTILE_SIMPTOMS = "h-erectile-simptoms",
  FERTILITY = "h-fertility",
  ALCOHOLIC = "h-alcoholic",
  ANEMIA = "h-anemia",
  COLDS = "h-colds",
  CONCENTRATING = "h-concentrating",
  ENERGY = "h-energy",
  SLEEP_QUALITY = "h-sleep-quality",
  SEEING = "h-seeing",
  DRY_EYES = "h-dry-eyes",
  STRESS = "h-stress",
  ATHLETE = "h-athlete",
  SWEAT = "h-sweat",
  WORKOUT = "h-workout",
  CRAMPS = "h-cramps",
  MUSCLE_INJURIES = "h-muscle-injuries",
  INJURIES = "h-injuries",
}

export enum LifestyleQuestionKey {
  ACTIVITY = "l-activity",
  MEAT = "l-meat",
  FISH = "l-fish",
  DIARY = "l-diary",
  FRUIT = "l-fruit",
  EGGS = "l-eggs",
  LEGUMES = "l-legumes",
  DIET = "l-diet",
  ALLERGIES = "l-allergic",
  MEDS = "l-meds",
  MEDICAL_COND = "l-medical-cond",
  CANCER = "l-cancer",
  SMOKE = "l-smoke",
  CAFFEINATE = "l-caffeinate",
  FULL_NAME = "l-full-name",
  EMAIL = "l-e-mail",
}

export enum NoneQuestionKey {
  UNDER_18 = "b-under-18",
}

export enum OtherRouteKey {
  ORDER_RECIPE = "order-recipe",
  LOADING = "loading",
  THANK_YOU = "thank-you",
}

export enum PaymentKey {
  INFORMATION = "pay-information",
  PAYMENT = "pay-payment",
}

export type QuestionKey = BasicQuestionKey | LifestyleQuestionKey | HealthQuestionKey;

export type RouteKey =
  | NoneQuestionKey
  | BasicQuestionKey
  | LifestyleQuestionKey
  | SectionKey
  | HealthQuestionKey
  | PaymentKey
  | OtherRouteKey;

export enum WhyType {
  whyWeAsk = "WHY",
  didYouKnow = "DID_YOU_KNOW",
  pleaseNote = "NOTE",
}
export interface Question {
  key: QuestionKey;
  active: boolean;
  type: QuestionType;
  hasWhyModal: boolean;
  whyType?: WhyType;
  hasHint?: boolean;
  subSection: SubSectionKey;
  activeGroup?: QuestionKey[];
}

export interface QuestionText extends Question {
  type: QuestionType.TEXT;
  label: string;
}
export interface QuestionDate extends Question {
  type: QuestionType.DATE;
}

export interface QuestionNumber extends Question {
  type: QuestionType.NUMBER;
}

export interface QuestionRadio extends Question {
  type: QuestionType.RADIO;
  allValues: QuestionOption[];
}

export interface QuestionYesNo extends Question {
  type: QuestionType.YES_NO;
}

export interface QuestionCheckbox extends Question {
  type: QuestionType.CHECKBOX;
  allValues: QuestionOptionCheckbox[];
  max?: number;
}

export interface QuestionMultiple extends Question {
  type: QuestionType.MULTIPLE;
  allValues: QuestionOption[];
  max?: number;
}

export interface QuestionTextPredictions extends Question {
  type: QuestionType.TEXT_PRED;
}

export interface QuestionTextPredictions extends Question {
  type: QuestionType.TEXT_PRED;
}

// ----- QUESTION ANSWERS -----
export interface AnswerText {
  value: string;
}
export interface AnswerNumber {
  value: number | null;
}

export interface AnswerNumberList {
  value: number[];
}

export type AnswerType = AnswerText | AnswerNumber | AnswerNumberList | { value: Medication[] };

export type QuestionAnswer = Record<QuestionKey, AnswerType>;
// ----- QUESTION ANSWERS -----

export enum YesNo {
  YES = "yes",
  NO = "no",
}

export enum USA_Height {
  FEET = "feet",
  INCHES = "inches",
}
