import { useMainStore } from "@/stores/main";
import { CheckoutVersion, FormulaPresentation, CheckoutShopify, SectionIntroABVersion } from "@/utils/constants";

// ----- Checkout Version -----
export const initializeCheckoutVersion = (to: any) => {
  const checkoutVersionUrlQuery = to.query.checkout_version || "";
  if (to.query.contactId && to.query.checkout_version) return true; // * This are Abandoned Recipe Links and Checkout version will be initialized from version saved on HS on Contact level

  if (checkoutVersionUrlQuery) {
    setCheckoutVersion(checkoutVersionUrlQuery);
  } else {
    window.addEventListener("setCheckoutVersion", setCheckoutVersionListener);
  }
};

export const setCheckoutVersionListener = (event: CustomEvent<CheckoutVersion>) => {
  setCheckoutVersion(event.detail);
};

export const setCheckoutVersion = (version: CheckoutVersion) => {
  const mainStore = useMainStore();
  mainStore.setCheckoutVersion(version);
  return true;
};
// ----- Checkout Version -----

// ----- Checkout Shopify Version -----
export const initializeCheckoutShopifyVersion = (to: any) => {
  const checkoutShopifyUrlQuery = to.query.checkout_shopify || "";
  if (to.query.contactId && to.query.checkout_shopify) return true; // * This are Abandoned Recipe Links and Checkout version will be initialized from version saved on HS on Contact level

  if (checkoutShopifyUrlQuery && Object.values(CheckoutShopify).includes(checkoutShopifyUrlQuery)) {
    setCheckoutShopifyVersion(checkoutShopifyUrlQuery);
  } else {
    window.addEventListener("setCheckoutShopifyVersionListener", setCheckoutShopifyVersionListener);
  }
};

export const setCheckoutShopifyVersionListener = (event: CustomEvent<CheckoutShopify>) => {
  setCheckoutShopifyVersion(event.detail);
};

export const setCheckoutShopifyVersion = (version: CheckoutShopify) => {
  const mainStore = useMainStore();
  mainStore.setCheckoutShopifyVersion(version);
  return true;
};
// ----- Checkout Shopify Version -----

// ----- Formula Presentation Version -----
export const initializeFormulaPresentation = (to: any) => {
  const formulaPresentationUrlQuery = to.query.formula_presentation || "";
  if (to.query.contactId && to.query.formula_presentation) return true; // * This are Abandoned Recipe Links and Formula version will be initialized from version saved on HS on Contact level

  if (formulaPresentationUrlQuery && Object.values(FormulaPresentation).includes(formulaPresentationUrlQuery)) {
    setFormulaPresentation(formulaPresentationUrlQuery);
  } else {
    window.addEventListener("formula_presentation", setFormulaPresentationListener);
  }
};

export const setFormulaPresentationListener = (event: CustomEvent<FormulaPresentation>) => {
  setFormulaPresentation(event.detail);
};

export const setFormulaPresentation = (version: FormulaPresentation) => {
  const mainStore = useMainStore();
  mainStore.setFormulaPresentation(version);
  return true;
};
// -----  Formula Presentation Version -----

// ----- Section Intro AB Version -----
export const initializeSectionIntroABTest = (to: any) => {
  const sectionIntroABVersionQuery = to.query.section_intro_version || "";
  if (to.query.contactId && to.query.section_intro_version) return true; // * This are Abandoned Recipe Links and Formula version will be initialized from version saved on HS on Contact level

  if (sectionIntroABVersionQuery && Object.values(SectionIntroABVersion).includes(sectionIntroABVersionQuery)) {
    setSectionIntroABVersion(sectionIntroABVersionQuery);
  } else {
    window.addEventListener("setSectionIntro", setSectionIntroABVersionListener);
  }
};

export const setSectionIntroABVersionListener = (event: CustomEvent<SectionIntroABVersion>) => {
  setSectionIntroABVersion(event.detail);
};

export const setSectionIntroABVersion = (version: SectionIntroABVersion) => {
  const mainStore = useMainStore();
  mainStore.setSectionIntroABVersion(version);
  return true;
};
// -----  Section Intro AB Version -----
