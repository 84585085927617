import { markRaw, createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import { initializeTranslations } from "@/includes/i18n-setup";
import VClickOutside from "@/directives/click-outside";
import VueGtag from "vue-gtag";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import { CampaignActiveInjectionKey } from "@/utils/constants";
import { CAMPAIGN } from "@/utils/campaign-trackers";
import initializeSentry from "@/services/Sentry";

initializeTranslations()
  .then((i18n: any) => {
    const app = createApp(App);
    const pinia = createPinia();

    pinia.use(({ store }) => {
      store.router = markRaw(router);
    });

    app.use(pinia);
    app.use(router);
    app.use(i18n);

    // ----- 3rd party UI components -----
    app.use(FloatingVue, {
      themes: {
        "currency-tooltip": {
          $extend: "tooltip",
          delay: { show: 50 },
        },
      },
    });
    app.component("MazPhoneNumberInput", MazPhoneNumberInput);
    // ----- 3rd party UI components -----

    const GTMScripts: any = {
      production: "GTM-5TFQ6F6",
      staging: "GTM-M5BLK8P6",
    };
    if (GTMScripts[import.meta.env.VITE_NODE_ENV]) {
      app.use(
        VueGtag,
        {
          config: { id: GTMScripts[import.meta.env.VITE_NODE_ENV] },
        },
        router
      );
    }
    app.directive("click-outside", VClickOutside);

    app.provide(CampaignActiveInjectionKey, CAMPAIGN);

    if (import.meta.env.VITE_NODE_ENV === "production") initializeSentry(app);

    app.mount("#app");
  })
  .catch((err) => {
    console.error("Could not start the application, ", err);
  });
