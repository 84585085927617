import { type QuestionKey, SectionKey, OtherRouteKey, PaymentKey } from "@/types/questions";
import { useSupplementsStore } from "@/stores/supplements";
import { RouteKeyToPathMapper } from "@/router/routes";
import router from "@/router/index";
import { sectionToNumberStep, LocalStorageKeys } from "@/utils/constants";
import { usePaymentStore } from "@/stores/payment";
import { useMainStore } from "@/stores/main";
import { useCrm } from "@/composables/useCrm";
import { useQuestionsStore } from "@/stores/questions";
import { sendFormulaGuardEvents } from "@/utils/analytics";

export const createQuestionRoute = (key: QuestionKey, sKey: SectionKey) => {
  if (router.hasRoute(key)) return { name: key };

  router.addRoute("main", {
    path: `${RouteKeyToPathMapper[key]}`,
    component: () => import("@/components/QuestionActive.vue"),
    name: key,
    meta: { qKey: key, sKey },
  });
  return { name: key };
};

export const createSectionRoute = (key: SectionKey) => {
  if (router.hasRoute(key)) return { name: key };

  router.addRoute("main", {
    path: `${RouteKeyToPathMapper[key]}`,
    component: () => import("@/views/SectionComp.vue"),
    name: key,
    props: { sectionNr: sectionToNumberStep[key] },
  });
  return { name: key };
};

export const shouldHaveSelectedProductAndCountry = () => {
  const paymentStore = usePaymentStore();
  return !!paymentStore.selectedProductId && !!paymentStore.selectedCountryCode;
};

export const redirectToOrderRecipeIfNotAllowed = (to: any) => {
  return shouldHaveSelectedProductAndCountry() || { name: OtherRouteKey.ORDER_RECIPE, query: to.query };
};

export const reloadIfFromThankYou = (_to: any, from: any, next: any) => {
  if (from.name !== OtherRouteKey.THANK_YOU) next();
  else location.reload();
};

export const informationMustBeFilled = () => {
  const paymentStore = usePaymentStore();
  return paymentStore.custInfAndDelValid;
};

export const resetStoreToIPStore = (_to: any, from: any) => {
  if (from.name !== PaymentKey.INFORMATION) return true;
  const paymentStore = usePaymentStore();
  const mainStore = useMainStore();

  paymentStore.setSelectedCountryCode(mainStore.ipLocCountry.code);
  return true;
};

export const loadContactFromCrm = async (to: any) => {
  const { shouldLoadContact, loadDataFromContact } = useCrm();
  if (!shouldLoadContact(to)) return true;

  const contactLoadedWithSuccess = await loadDataFromContact(to);
  const formulaExists = sendFormulaGuardEvents();

  const redirectUrl =
    import.meta.env.VITE_NODE_ENV === "production"
      ? import.meta.env.VITE_LP_URL_PROD
      : import.meta.env.VITE_LP_URL_STAGING;

  if (contactLoadedWithSuccess && formulaExists) return true;
  else {
    window.location.replace(redirectUrl);
  }

  return true as never;
};

export const checkForFormulaUuid = async (to: any) => {
  const supplementsStore = useSupplementsStore();
  console.log("supplementsStore.formulaDraftUuid", supplementsStore.formulaDraftUuid);
  if (!supplementsStore.formulaDraftUuid) return { name: OtherRouteKey.LOADING, query: to.query };
  return true;
};

export const checkThankYouQueryParams = (to: any) => {
  if (to.query.free || to.query.secret) return true;

  return { name: SectionKey.INTRO, query: to.query };
};

export const checkThankYouLocalStorageData = () => {
  const necessaryData = [
    localStorage.getItem(LocalStorageKeys.ty_email),
    localStorage.getItem(LocalStorageKeys.ty_fullName),
    localStorage.getItem(LocalStorageKeys.ty_phone),
    localStorage.getItem(LocalStorageKeys.ty_payment),
    localStorage.getItem(LocalStorageKeys.ty_delivery),
    localStorage.getItem(LocalStorageKeys.selectedPaymentMethodType),
    localStorage.getItem(LocalStorageKeys.selectedProductCategory),
  ];
  const allValid = necessaryData.every((el) => !!el);
  if (allValid) return true;

  return { name: SectionKey.INTRO };
};

export const saveQuestionnaireToLS = () => {
  const questStore = useQuestionsStore();
  questStore.saveDataToStorage();
};
