import { defineStore } from "pinia";
import { useBasicsStore } from "@/stores/basics";
import { useHealthStore } from "@/stores/health";
import { useLifestyleStore } from "@/stores/lifestyle";
import {
  type Question,
  type QuestionKey,
  BasicQuestionKey,
  type QuestionAnswer,
  QuestionType,
  HealthQuestionKey,
  LifestyleQuestionKey,
  SectionKey,
  type QuestionRadio,
  type QuestionCheckbox,
  type AnswerNumber,
  type AnswerText,
  type AnswerNumberList,
} from "@/types/questions";
import {
  LocalStorageKeys,
  NO_ANSWER_GIVEN,
  YesNoOptions,
  prefillBasicAnswers,
  prefillHealthAnswers,
  prefillLifeStyleAnswers,
} from "@/utils/constants";
import { findValueByIdInOptionsList, hasAnswer } from "@/utils/functions";
import type { Medication } from "@/types/apiTypes";
import { useMainStore } from "./main";

declare module "pinia" {
  export interface PiniaCustomProperties {
    router: any;
  }
}

const allStoresInitialized = () => {
  const basicStore = useBasicsStore();
  const healthStore = useHealthStore();
  const lifestyleStore = useLifestyleStore();
  return () => {
    return { basicStore, healthStore, lifestyleStore };
  };
};

export const useQuestionsStore = defineStore({
  id: "questions",
  state: () =>
    ({
      activeQuestionKey: HealthQuestionKey.GENDER as QuestionKey,
      prevActiveQuestionKey: null,
      activeSectionKey: SectionKey.INTRO,
    } as {
      activeQuestionKey: QuestionKey | null;
      prevActiveQuestionKey: QuestionKey | null;
      activeSectionKey: SectionKey;
    }),
  getters: {
    allStores(): () => {
      basicStore: ReturnType<typeof useBasicsStore>;
      healthStore: ReturnType<typeof useHealthStore>;
      lifestyleStore: ReturnType<typeof useLifestyleStore>;
    } {
      return allStoresInitialized();
    },
    basicStore(): any {
      return this.allStores().basicStore;
    },
    healthStore(): any {
      return this.allStores().healthStore;
    },
    lifestyleStore(): any {
      return this.allStores().lifestyleStore;
    },
    allAnswers(): QuestionAnswer {
      return {
        ...this.healthStore.answers,
        ...this.basicStore.answers,
        ...this.lifestyleStore.answers,
      };
    },
    allQuestions(): Question[] {
      return [...this.healthStore.questions, ...this.basicStore.questions, ...this.lifestyleStore.questions];
    },
    activeQuestion(): Question {
      return this.allQuestions.find((q) => q.key === this.activeQuestionKey) as Question;
    },
    activeQuestionList(): Question[] {
      return this.allQuestions.filter((q) => !!q.active);
    },
    allActiveQuestionsAreAnswered(): boolean {
      return this.activeQuestionList.every((question) => {
        const answer = this.allAnswers[question.key];
        if (!answer) return false;

        if (typeof answer.value === "string") return !!answer.value;
        if (typeof answer.value === "number") return !!answer.value;
        if (Array.isArray(answer.value)) {
          if (question.type !== QuestionType.RADIO) return true;
          return !!answer.value.length;
        }
        return false;
      });
    },
    currentStepNumber(): number {
      return this.activeQuestionList.findIndex((q) => q.key === this.activeQuestionKey) + 1;
    },
    activeQuestionsNumber(): number {
      return this.activeQuestionList.length;
    },
    activeStoreForActiveQuestion(): any {
      if (Object.values(BasicQuestionKey).includes(this.activeQuestionKey as BasicQuestionKey)) return this.basicStore;
      if (Object.values(HealthQuestionKey).includes(this.activeQuestionKey as HealthQuestionKey))
        return this.healthStore;
      if (Object.values(LifestyleQuestionKey).includes(this.activeQuestionKey as LifestyleQuestionKey))
        return this.lifestyleStore;
    },
    storeForQuestion(): any {
      return (qKey: QuestionKey) => {
        if (this.basicStore.questions.findIndex((q: Question) => q.key === qKey) > -1) return this.basicStore;
        if (this.healthStore.questions.findIndex((q: Question) => q.key === qKey) > -1) return this.healthStore;
        if (this.lifestyleStore.questions.findIndex((q: Question) => q.key === qKey) > -1) return this.lifestyleStore;
      };
    },
    storeForSection(): any {
      return (sKey: SectionKey) => {
        if (sKey === SectionKey.BASICS) return this.basicStore;
        if (sKey === SectionKey.HEALTH) return this.healthStore;
        if (sKey === SectionKey.LIFESTYLE) return this.lifestyleStore;
      };
    },
    formattedQuestionsForStorage(): any {
      return {
        basicQ: this.basicStore.questions,
        healthQ: this.healthStore.questions,
        lifestyleQ: this.lifestyleStore.questions,
      };
    },
    readableAnswers() {
      const answers: { [key in QuestionKey]: string | string[] } = Object.entries(this.allAnswers).reduce(
        (result, [qKey, qAnswer]) => {
          let newValue: string | string[] = "";
          if (qAnswer.value && hasAnswer(qAnswer.value)) {
            const question = this.allQuestions.find((q) => q.key === qKey) as Question;
            switch (question.type) {
              case QuestionType.YES_NO:
                newValue = findValueByIdInOptionsList(YesNoOptions, (qAnswer as AnswerNumber).value!);
                break;
              case QuestionType.CHECKBOX:
              case QuestionType.MULTIPLE:
                newValue = (qAnswer as AnswerNumberList).value!.map((answerId: number) =>
                  findValueByIdInOptionsList((question as QuestionCheckbox).allValues, answerId)
                );
                break;
              case QuestionType.RADIO:
                newValue = findValueByIdInOptionsList(
                  (question as QuestionRadio).allValues,
                  (qAnswer as AnswerNumber).value!
                );
                break;
              case QuestionType.TEXT_PRED:
                newValue = (qAnswer.value as Medication[]).map((med: Medication) => med.medicationName);
                break;
              case QuestionType.NUMBER_HEIGHT_USA:
                newValue = `${this.basicStore.answerHeightInInches} inches`;
                break;
              case QuestionType.DATE:
                newValue = this.basicStore.answerDob_YYYYMMDDFormat;
                break;
              case QuestionType.NUMBER:
                newValue = qAnswer.value.toString();
                if (qKey === BasicQuestionKey.WEIGHT) {
                  newValue += ` ${this.weightMeasurementUnit}`;
                }
                if (qKey === BasicQuestionKey.HEIGHT) {
                  newValue = `${this.basicStore.answerHeightInCm} cm`;
                }
                break;
              default:
                // text
                newValue = qAnswer.value.toString();
            }
          } else newValue = NO_ANSWER_GIVEN;
          result[qKey as QuestionKey] = newValue;
          return result;
        },
        {} as { [key in QuestionKey]: string | string[] }
      );
      const formattedAnswers: string = Object.entries(answers)
        .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(", ") : value}`)
        .join("\n");
      return formattedAnswers;
    },
    questionsWithUSAVariations(): QuestionKey[] {
      return [BasicQuestionKey.DOB, BasicQuestionKey.WEIGHT, BasicQuestionKey.HEIGHT];
    },
    getAnswerInDefaultSystem: (state) => {
      return (qKey: QuestionKey): any => {
        switch (qKey) {
          case BasicQuestionKey.WEIGHT:
            return (state as any).basicStore.answerWeightInKg;
          case BasicQuestionKey.HEIGHT:
            return (state as any).basicStore.answerHeightInCm;
          case BasicQuestionKey.DOB:
            return (state as any).basicStore.answerDob_YYYYMMDDFormat;
          default:
            return null;
        }
      };
    },
    weightMeasurementUnit(): "kg" | "lbs" {
      const mainStore = useMainStore();
      return mainStore.questionnaireWasMadeInUSAFormat ? "lbs" : "kg";
    },
    heightMeasurementUnit(): "cm" | "inches" {
      const mainStore = useMainStore();
      return mainStore.questionnaireWasMadeInUSAFormat ? "inches" : "cm";
    },
    profileQuestions(): {
      gender: "1" | "2" | "3";
      dateOfBirth: string;
      weight: number;
      height: number;
      weightUnit: "kg" | "lbs";
      heightUnit: "cm" | "inches";
    } {
      const mainStore = useMainStore();
      const healthStore = useHealthStore();
      const basicsStore = useBasicsStore();

      const gender = (healthStore.answers[HealthQuestionKey.GENDER] as AnswerNumber).value;
      const weight = parseFloat((basicsStore.answers[BasicQuestionKey.WEIGHT] as AnswerText).value);
      const height = mainStore.questionnaireWasMadeInUSAFormat
        ? (basicsStore.answerHeightInInches as number)
        : basicsStore.answerHeightInCm;
      const dateOfBirth = basicsStore.answerDob_YYYYMMDDFormat;

      return {
        gender: gender!.toString() as "1" | "2" | "3",
        dateOfBirth,
        weight,
        height,
        weightUnit: this.weightMeasurementUnit,
        heightUnit: this.heightMeasurementUnit,
      };
    },
  },
  actions: {
    updateAnswer(key: QuestionKey, answerVal: any) {
      this.activeStoreForActiveQuestion.updateAnswer(this.updateAnswerBasedOnQuestionType(key, answerVal));
    },
    updateAnswerBasedOnQuestionType(key: QuestionKey, val: string) {
      switch (this.activeQuestion.type) {
        case QuestionType.TEXT:
          return (answers: QuestionAnswer) => (answers[key].value = val.trim());
        case QuestionType.NUMBER:
          return (answers: QuestionAnswer) => (answers[key].value = val);
        case QuestionType.DATE:
          return (answers: QuestionAnswer) => (answers[key].value = val);
        case QuestionType.RADIO:
          return (answers: QuestionAnswer) => (answers[key].value = val);
        case QuestionType.YES_NO:
          return (answers: QuestionAnswer) => (answers[key].value = val);
        case QuestionType.CHECKBOX:
          return (answers: QuestionAnswer) => (answers[key].value = val);
        case QuestionType.MULTIPLE:
          return (answers: QuestionAnswer) => (answers[key].value = val);
        case QuestionType.TEXT_PRED:
          return (answers: QuestionAnswer) => (answers[key].value = val);
        case QuestionType.NUMBER_HEIGHT_USA:
          return (answers: QuestionAnswer) => (answers[key].value = val);
        default:
          break;
      }
    },
    goNext() {
      const to = this.activeStoreForActiveQuestion.goToNextStep();
      this.router.push(to);
    },
    goBack() {
      return this.router.back();
    },
    setActiveQuestionKey(key: QuestionKey | null) {
      this.prevActiveQuestionKey = this.activeQuestionKey;
      this.activeQuestionKey = key;
    },
    isFirstInSection(key: QuestionKey | null): boolean {
      if (!key) return false;
      const firstKeys = [
        this.basicStore.firstQuestionKey,
        this.healthStore.firstQuestionKey,
        this.lifestyleStore.firstQuestionKey,
      ];
      return firstKeys.includes(key);
    },
    didGoBack() {
      if (this.isFirstInSection(this.prevActiveQuestionKey) && !this.activeQuestionKey) return true;
      if (!this.activeQuestionKey || !this.prevActiveQuestionKey) return false;
      const activeIndex = this.allQuestions.findIndex((q) => q.key === this.activeQuestionKey);
      const prevIndex = this.allQuestions.findIndex((q) => q.key === this.prevActiveQuestionKey);
      return activeIndex < prevIndex;
    },
    deactivateQuestionsForPrevQ() {
      const prevQ = this.allQuestions.find((q) => q.key === this.prevActiveQuestionKey) as Question;
      if (prevQ.activeGroup && prevQ.activeGroup.length) {
        prevQ.activeGroup.forEach((qKey) => this.toggleActiveKeyForQuestion(qKey, false));
      }
    },
    toggleActiveKeyForQuestion(key: QuestionKey, val: boolean) {
      this.storeForQuestion(key).toggleActiveKey(
        (questions: Question[]) => (questions.find((q) => q.key === key)!.active = val)
      );
    },
    setNextActiveQuestions() {
      this.activeQuestion.active = true;
      if (this.activeQuestionKey === HealthQuestionKey.CONCERNS) this.healthStore.deactivateQuestions();

      if (this.didGoBack()) this.deactivateQuestionsForPrevQ();

      if (!this.activeQuestion.activeGroup || !this.activeQuestion.activeGroup.length) return;

      this.activeQuestion.activeGroup.forEach((qKey) => this.toggleActiveKeyForQuestion(qKey, true));
    },
    updateAnswersForAllStores(answers: QuestionAnswer) {
      const stores = [this.basicStore, this.healthStore, this.lifestyleStore];
      stores.forEach((store) => {
        Object.keys(store.answers).forEach((key) => {
          if (answers[key as QuestionKey]) store.answers[key] = answers[key as QuestionKey];
        });
      });
    },
    loadAnswersFromStorage() {
      const answersJson = localStorage.getItem(LocalStorageKeys.answer);
      const answers = answersJson ? JSON.parse(answersJson) : null;
      if (!answers) return;
      this.updateAnswersForAllStores(answers);
      const consent = localStorage.getItem(LocalStorageKeys.marketingConsent);
      const smsConsent = localStorage.getItem(LocalStorageKeys.smsConsent);
      this.lifestyleStore.marketingConsentSelected = consent === "true";
      this.lifestyleStore.smsConsentSelected = smsConsent === "true";
    },
    saveDataToStorage() {
      //questions
      localStorage.setItem(LocalStorageKeys.questions, JSON.stringify(this.formattedQuestionsForStorage));
      // answers
      localStorage.setItem(LocalStorageKeys.answer, JSON.stringify(this.allAnswers));
      localStorage.setItem(LocalStorageKeys.marketingConsent, this.lifestyleStore.marketingConsentSelected);
      localStorage.setItem(LocalStorageKeys.smsConsent, this.lifestyleStore.smsConsentSelected);
      // active page
      const activePage = {
        qKey: this.activeQuestionKey || "",
        sKey: this.activeSectionKey,
      };
      localStorage.setItem(LocalStorageKeys.lastPage, JSON.stringify(activePage));
    },
    updateActivePropForQuestions(fromQuestions: Question[], toQuestions: Question[]) {
      fromQuestions.forEach((qStore) => {
        const toQuest = toQuestions.find((q) => q.key === qStore.key);
        if (toQuest) toQuest.active = qStore.active;
      });
    },
    loadQuestionsFromStorage() {
      const questionsJson = localStorage.getItem(LocalStorageKeys.questions);
      if (!questionsJson) return;
      const questions = JSON.parse(questionsJson);
      this.updateActivePropForQuestions(questions.basicQ, this.basicStore.questions);
      this.updateActivePropForQuestions(questions.healthQ, this.healthStore.questions);
      this.updateActivePropForQuestions(questions.lifestyleQ, this.lifestyleStore.questions);
    },
    setActiveSectionKey(sKey: SectionKey, removeActiveQuestionKey = false) {
      this.activeSectionKey = sKey;
      if (removeActiveQuestionKey) this.activeQuestionKey = null;
    },
    deactivateAllOptionalQuestions() {
      this.healthStore.deactivateQuestions();
      this.allQuestions.forEach((q) => {
        if (q.activeGroup) q.activeGroup.forEach((qKey) => this.toggleActiveKeyForQuestion(qKey, false));
      });
    },
    prefillQuestionnaire() {
      this.healthStore.answers = prefillHealthAnswers;
      this.basicStore.answers = prefillBasicAnswers;
      this.lifestyleStore.answers = prefillLifeStyleAnswers;
      this.lifestyleStore.marketingConsentSelected = true;
    },
  },
});
