import { defineStore } from "pinia";
import type { Supplement, Ingredient, SupplementFormula } from "@/types/apiTypes";
import snakecaseKeys from "snakecase-keys";

export const useSupplementsStore = defineStore({
  id: "supplements",
  state: () => ({
    supplementsFormula: null as SupplementFormula | null,
    apiFetched: false,
  }),
  getters: {
    formulaDraftUuid(): string {
      return this.supplementsFormula?.formulaDraftUuid || "";
    },
    getFormulaForSubmitApi(): { status: string; supplements: Supplement[]; ingredients: Ingredient[] } {
      return snakecaseKeys({
        status: this.supplementsFormula!.status,
        supplements: this.supplementsFormula!.supplements,
        ingredients: this.supplementsFormula!.ingredients,
      });
    },
  },
  actions: {
    setFormula(data: SupplementFormula) {
      this.supplementsFormula = data;
    },
    setApiFetched(val: boolean) {
      this.apiFetched = val;
    },
  },
});
