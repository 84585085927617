import { defineStore } from "pinia";
import {
  type Question,
  type QuestionText,
  QuestionType,
  BasicQuestionKey,
  type QuestionAnswer,
  type AnswerText,
  SubSectionKey,
  type AnswerNumber,
  type QuestionYesNo,
  type QuestionCheckbox,
  type AnswerNumberList,
  SectionKey,
  type RouteKey,
  type QuestionNumber,
  type QuestionKey,
  type QuestionDate,
  NoneQuestionKey,
  WhyType,
} from "@/types/questions";
import { useQuestionsStore } from "@/stores/questions";
import { YesNoOptions } from "@/utils/constants";
import { createQuestionRoute, createSectionRoute } from "@/router/guards";
import { findValueByIdInOptionsList } from "@/utils/functions";
import moment from "moment";
import { useMainStore } from "./main";

const basicQuestions: Question[] = [
  {
    key: BasicQuestionKey.DOB,
    step: 0,
    active: true,
    type: QuestionType.DATE,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.BASICS,
  } as QuestionDate,
  {
    key: BasicQuestionKey.HEIGHT,
    step: 3,
    active: true,
    type: QuestionType.NUMBER, // * Be aware that this could be changed to QuestionType.NUMBER_HEIGHT_USA at this route guard: "adaptQuestionsType"
    hasWhyModal: true,
    whyType: WhyType.whyWeAsk,
    subSection: SubSectionKey.BASICS,
  } as QuestionNumber,
  {
    key: BasicQuestionKey.WEIGHT,
    step: 0,
    active: true,
    type: QuestionType.NUMBER,
    hasWhyModal: false,
    subSection: SubSectionKey.BASICS,
  } as QuestionNumber,
  {
    key: BasicQuestionKey.PRE_POST_NATAL,
    step: 0,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.PRENATAL,
    activeGroup: [BasicQuestionKey.PRE_POST_NATAL, BasicQuestionKey.ASK_REPRODUCTIVE],
  } as QuestionYesNo,
  {
    key: BasicQuestionKey.PREGNANT_OR_PLANNING,
    step: 0,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.PRENATAL,
    hasHint: true,
    activeGroup: [
      BasicQuestionKey.PREGNANT_OR_PLANNING,
      BasicQuestionKey.MENSTRUAL,
      BasicQuestionKey.PREMENSTRUAL,
      BasicQuestionKey.MENOPAUSE,
    ],
  } as QuestionYesNo,
  {
    key: BasicQuestionKey.WHICH_DESCRIBES,
    step: 0,
    active: false,
    type: QuestionType.CHECKBOX,
    hasWhyModal: false,
    subSection: SubSectionKey.PRENATAL,
    allValues: [
      { id: 4, value: "looking", phraseKey: "LOOKING_PREGNANT" },
      { id: 5, value: "pregnant", phraseKey: "PREGNANT" },
      { id: 6, value: "nursing", phraseKey: "NURSING" },
    ],
    max: 2,
  } as QuestionCheckbox,
  {
    key: BasicQuestionKey.PREGNANCY_WEEKS,
    step: 0,
    active: false,
    type: QuestionType.NUMBER,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.PRENATAL,
  } as QuestionNumber,
  {
    key: BasicQuestionKey.ASK_REPRODUCTIVE,
    step: 0,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: false,
    subSection: SubSectionKey.REPRODUCTIVE_HEALTH,
  } as QuestionYesNo,
  {
    key: BasicQuestionKey.MENSTRUAL,
    step: 0,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.REPRODUCTIVE_HEALTH,
  } as QuestionYesNo,
  {
    key: BasicQuestionKey.PREMENSTRUAL,
    step: 0,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.REPRODUCTIVE_HEALTH,
  } as QuestionYesNo,
  {
    key: BasicQuestionKey.MENOPAUSE,
    step: 0,
    active: false,
    type: QuestionType.YES_NO,
    hasWhyModal: true,
    whyType: WhyType.didYouKnow,
    subSection: SubSectionKey.REPRODUCTIVE_HEALTH,
  } as QuestionYesNo,
];

export const useBasicsStore = defineStore({
  id: "basics",
  state: () => ({
    questions: basicQuestions,
    answers: {
      "b-dob": { value: "" } as AnswerText,
      "b-height": { value: "" } as AnswerText,
      "b-weight": { value: "" } as AnswerText,
      "b-pre-post-natal": { value: null } as AnswerNumber,
      "b-pregnant-or-planning": { value: null } as AnswerNumber,
      "b-which-describes": { value: [] } as AnswerNumberList,
      "b-pregnancy-weeks": { value: "" } as AnswerText,
      "b-ask-reproductive": { value: null } as AnswerNumber,
      "b-menstrual": { value: null } as AnswerNumber,
      "b-premenstrual": { value: null } as AnswerNumber,
      "b-menopause": { value: null } as AnswerNumber,
    } as QuestionAnswer,
  }),
  getters: {
    activeBasicQuestion(): Question {
      const questStore = useQuestionsStore();
      return this.questions.find((q) => q.key === questStore.activeQuestionKey) as Question;
    },
    questHeight(): QuestionText {
      return this.questions.find((q) => q.key === BasicQuestionKey.HEIGHT) as QuestionText;
    },
    questWeight(): QuestionText {
      return this.questions.find((q) => q.key === BasicQuestionKey.WEIGHT) as QuestionText;
    },
    questPrePostNatalHealth(): QuestionYesNo {
      return this.questions.find((q) => q.key === BasicQuestionKey.PRE_POST_NATAL) as QuestionYesNo;
    },
    questPregnantOrPlanning(): QuestionYesNo {
      return this.questions.find((q) => q.key === BasicQuestionKey.PREGNANT_OR_PLANNING) as QuestionYesNo;
    },
    questWhichDescribes(): QuestionCheckbox {
      return this.questions.find((q) => q.key === BasicQuestionKey.WHICH_DESCRIBES) as QuestionCheckbox;
    },
    questPregnancyWeeks(): QuestionText {
      return this.questions.find((q) => q.key === BasicQuestionKey.PREGNANCY_WEEKS) as QuestionText;
    },
    questAskReproductive(): QuestionYesNo {
      return this.questions.find((q) => q.key === BasicQuestionKey.ASK_REPRODUCTIVE) as QuestionYesNo;
    },
    questMenstrual(): QuestionYesNo {
      return this.questions.find((q) => q.key === BasicQuestionKey.MENSTRUAL) as QuestionYesNo;
    },
    questPremestrual(): QuestionYesNo {
      return this.questions.find((q) => q.key === BasicQuestionKey.PREMENSTRUAL) as QuestionYesNo;
    },
    questMenopause(): QuestionYesNo {
      return this.questions.find((q) => q.key === BasicQuestionKey.MENOPAUSE) as QuestionYesNo;
    },
    isInterestedInPrenatalPostnatalHealth(): boolean {
      return this.answers[this.questPrePostNatalHealth.key].value === YesNoOptions[0].id;
    },
    isPregnantOrPlanning(): boolean {
      return this.answers[BasicQuestionKey.PREGNANT_OR_PLANNING].value === YesNoOptions[0].id;
    },
    lookingPregnantNursing(): ("looking" | "pregnant" | "nursing")[] {
      const answerIds: number[] = (this.answers[BasicQuestionKey.WHICH_DESCRIBES] as AnswerNumberList).value;
      return answerIds.map(
        (id) => findValueByIdInOptionsList(this.questWhichDescribes.allValues, id) as "looking" | "pregnant" | "nursing"
      );
    },
    firstQuestionKey(): QuestionKey {
      return this.questions[0].key;
    },
    answerWeightInKg(): number {
      const val = (this.answers[BasicQuestionKey.WEIGHT] as AnswerText).value;
      const mainStore = useMainStore();
      if (!mainStore.isLocationUSA) return parseFloat(val);

      return parseFloat((parseFloat(val) / 2.2).toFixed(2));
    },
    answerHeightInInches(): number | null {
      const val = (this.answers[BasicQuestionKey.HEIGHT] as AnswerText).value;
      const mainStore = useMainStore();
      if (!mainStore.isLocationUSA) return null;

      const feetToInch = parseInt(val.split(`'`)[0]) * 12;
      const inches = parseInt(val.split(`'`)[1]);
      return feetToInch + inches;
    },
    answerHeightInCm(): number {
      const val = (this.answers[BasicQuestionKey.HEIGHT] as AnswerText).value;
      const mainStore = useMainStore();
      if (!mainStore.isLocationUSA) return parseFloat(val);

      return Math.round((this.answerHeightInInches as number) * 2.54);
    },
    answerDob_YYYYMMDDFormat(): string {
      const mainStore = useMainStore();
      const format = mainStore.questionnaireWasMadeInUSAFormat ? "MM/DD/YYYY" : "DD/MM/YYYY";
      return moment((this.answers[BasicQuestionKey.DOB] as AnswerText).value, format).format("YYYY-MM-DD");
    },
  },
  actions: {
    updateAnswer(cb: (answers: QuestionAnswer) => void) {
      cb(this.answers);
    },
    isUnder18(date: string): boolean {
      return moment().diff(moment(date, "YYYY-MM-DD"), "years") < 18;
    },
    getNextRouteByGender(gender: "female" | "male" | "none") {
      switch (gender) {
        case "female":
          return createQuestionRoute(BasicQuestionKey.PREGNANT_OR_PLANNING, SectionKey.BASICS);
        case "male":
          return createSectionRoute(SectionKey.LIFESTYLE);
        case "none":
          return createQuestionRoute(BasicQuestionKey.PRE_POST_NATAL, SectionKey.BASICS);
        default:
          return createSectionRoute(SectionKey.LIFESTYLE) as never;
      }
    },
    goToNextStep() {
      const questStore = useQuestionsStore();
      let to: { name: RouteKey } = { name: SectionKey.INTRO };

      switch (questStore.activeQuestionKey) {
        case BasicQuestionKey.DOB:
          if (this.isUnder18(this.answerDob_YYYYMMDDFormat)) {
            to = { name: NoneQuestionKey.UNDER_18 };
          } else {
            to = createQuestionRoute(BasicQuestionKey.HEIGHT, SectionKey.BASICS);
          }
          break;
        case BasicQuestionKey.HEIGHT:
          to = createQuestionRoute(BasicQuestionKey.WEIGHT, SectionKey.BASICS);
          break;
        case BasicQuestionKey.WEIGHT:
          to = this.getNextRouteByGender(questStore.healthStore.gender);
          break;
        case BasicQuestionKey.PRE_POST_NATAL:
          if (this.isInterestedInPrenatalPostnatalHealth) {
            to = createQuestionRoute(BasicQuestionKey.PREGNANT_OR_PLANNING, SectionKey.BASICS);
            this.questPregnantOrPlanning.activeGroup = [
              BasicQuestionKey.PREGNANT_OR_PLANNING,
              BasicQuestionKey.MENSTRUAL,
              BasicQuestionKey.PREMENSTRUAL,
              BasicQuestionKey.MENOPAUSE,
            ];
          } else {
            to = createQuestionRoute(BasicQuestionKey.ASK_REPRODUCTIVE, SectionKey.BASICS);
            this.questPregnantOrPlanning.activeGroup = [BasicQuestionKey.ASK_REPRODUCTIVE];
          }
          break;
        case BasicQuestionKey.PREGNANT_OR_PLANNING:
          if (this.isPregnantOrPlanning) {
            to = createQuestionRoute(BasicQuestionKey.WHICH_DESCRIBES, SectionKey.BASICS);
            this.questWhichDescribes.activeGroup = [
              BasicQuestionKey.WHICH_DESCRIBES,
              BasicQuestionKey.MENSTRUAL,
              BasicQuestionKey.PREMENSTRUAL,
              BasicQuestionKey.MENOPAUSE,
            ];
          } else {
            to = createQuestionRoute(BasicQuestionKey.MENSTRUAL, SectionKey.BASICS);
            this.questMenstrual.activeGroup = [
              BasicQuestionKey.MENSTRUAL,
              BasicQuestionKey.PREMENSTRUAL,
              BasicQuestionKey.MENOPAUSE,
            ];
          }
          break;
        case BasicQuestionKey.WHICH_DESCRIBES:
          if (this.lookingPregnantNursing.includes("pregnant")) {
            to = createQuestionRoute(BasicQuestionKey.PREGNANCY_WEEKS, SectionKey.BASICS);
            this.questPregnancyWeeks.activeGroup = [
              BasicQuestionKey.PREGNANCY_WEEKS,
              BasicQuestionKey.MENSTRUAL,
              BasicQuestionKey.PREMENSTRUAL,
              BasicQuestionKey.MENOPAUSE,
            ];
          } else {
            to = createQuestionRoute(BasicQuestionKey.MENSTRUAL, SectionKey.BASICS);
            this.questPregnancyWeeks.activeGroup = [
              BasicQuestionKey.MENSTRUAL,
              BasicQuestionKey.PREMENSTRUAL,
              BasicQuestionKey.MENOPAUSE,
            ];
          }
          break;
        case BasicQuestionKey.PREGNANCY_WEEKS:
          to = createQuestionRoute(BasicQuestionKey.MENSTRUAL, SectionKey.BASICS);
          this.questMenstrual.activeGroup = [
            BasicQuestionKey.MENSTRUAL,
            BasicQuestionKey.PREMENSTRUAL,
            BasicQuestionKey.MENOPAUSE,
          ];
          break;
        case BasicQuestionKey.ASK_REPRODUCTIVE:
          if (this.answers[this.questAskReproductive.key].value === YesNoOptions[0].id) {
            to = createQuestionRoute(BasicQuestionKey.MENSTRUAL, SectionKey.BASICS);
            this.questMenstrual.activeGroup = [
              BasicQuestionKey.MENSTRUAL,
              BasicQuestionKey.PREMENSTRUAL,
              BasicQuestionKey.MENOPAUSE,
            ];
          } else if (this.answers[this.questAskReproductive.key].value === YesNoOptions[1].id) {
            to = createSectionRoute(SectionKey.LIFESTYLE);
          }
          break;
        case BasicQuestionKey.MENSTRUAL:
          to = createQuestionRoute(BasicQuestionKey.PREMENSTRUAL, SectionKey.BASICS);
          this.questMenstrual.activeGroup = [BasicQuestionKey.PREMENSTRUAL, BasicQuestionKey.MENOPAUSE];
          break;
        case BasicQuestionKey.PREMENSTRUAL:
          to = createQuestionRoute(BasicQuestionKey.MENOPAUSE, SectionKey.BASICS);
          this.questMenstrual.activeGroup = [BasicQuestionKey.MENOPAUSE];
          break;
        case BasicQuestionKey.MENOPAUSE:
          to = createSectionRoute(SectionKey.LIFESTYLE);
          break;
        default:
          break;
      }
      return to;
    },
    toggleActiveKey(cb: (questions: Question[]) => void) {
      cb(this.questions);
    },
  },
});
