import { createI18n, type I18n } from "vue-i18n";
import { type LOCALE, DEFAULT_LOCALE } from "@/utils/constants";
import axios from "axios";
import type { Ref } from "vue";

export let i18n: I18n;

export const addLanguageToI18N = (locale: LOCALE, data: any) => {
  i18n.global.setLocaleMessage(locale, data);
};

export const changeAppLocale = (locale: LOCALE) => {
  (i18n.global.locale as any).value = locale;
};

export const fetchLocale = async (locale: LOCALE) => {
  const pathLang = `${import.meta.env.BASE_URL}lang/${locale}.json`;
  try {
    const response = await axios.get(pathLang, {
      headers: {
        "content-type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching locale ${locale},`, error);
    return null;
  }
};

export const initializeTranslations = async () => {
  const res = await fetchLocale(DEFAULT_LOCALE);

  i18n = createI18n({
    legacy: false,
    locale: DEFAULT_LOCALE, // set locale
    fallbackLocale: DEFAULT_LOCALE,
    messages: { [DEFAULT_LOCALE]: res }, // set locale messages
  });
  return i18n;
};

export const setI18nLanguage = (lang: LOCALE, translations: any) => {
  (i18n.global.locale as Ref).value = lang;
  addLanguageToI18N(lang, translations);

  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html")!.setAttribute("lang", lang);
  return lang;
};
